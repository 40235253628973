import { useCallback, useState } from "react";

import axios from "axios";
import { useStytch, useStytchUser } from "@stytch/react";
import { useDispatch } from "react-redux";
import { globalAlertFail, globalAlertSuccess } from "../redux/actions/globalAlertActions";
import { apiDomains } from "../global/api/apiDomains";
import { mapNestedObjectFormData } from "../helpers/mapObjectFormData";
import { isNil } from "lodash";

const getValueFromMappedObject = (objectMap, mapKey, defaultValueString) => {
  const objectMapKeys = Object.keys(objectMap);
  let domainMapKeyDefault = defaultValueString ? defaultValueString : objectMapKeys[0];

  let domainMapKey = isNil(mapKey) ? domainMapKeyDefault : mapKey;

  return objectMap[domainMapKey];
};

export const useAxiosHook = () => {
  const dispatch = useDispatch();

  //Exported State
  const [axiosPostIsLoading, setAxiosPostIsLoading] = useState(false);
  const [axiosGetIsLoading, setAxiosGetIsLoading] = useState(false);
  const [axiosError, setAxiosError] = useState(null);

  const domainTypeMap = {
    default: "https://ironlinc-dev.azurewebsites.net",
    crm_v2: "https://ironlinc-crm.nicemeadow-604c81f6.eastus.azurecontainerapps.io",
  };
  //---Exported Functions---
  //Get Request
  const axiosGetRequest = useCallback(async ({ name, path, responseType, onSuccessFn, onFailFn, onFailMsg, type }) => {
    setAxiosGetIsLoading(true);
    console.log("<--------");
    console.log(
      `%cGET |>| ${name} || path --> ${getValueFromMappedObject(domainTypeMap, type)}/${path}`,
      "background: #08a6081e;"
    );

    // console.log("STOP GET");
    // return;

    axios({
      url: `${getValueFromMappedObject(domainTypeMap, type)}/${path}`,
      method: "get",
      responseType: responseType ? responseType : "json",
    })
      .then((response) => {
        const responseData = response.data;

        console.log(`%cGET |<| ${name} || Response Data --> `, "background: #08a6083b;", responseData);

        console.log("-------->");
        if (onSuccessFn) {
          onSuccessFn(responseData, response);
        }
        setAxiosGetIsLoading(false);
      })
      .catch((err) => {
        console.log(`%cGET |<| ${name} || Error --> `, "background: #ff00001a;", err);
        console.log("-------->");
        setAxiosError(err.message);
        dispatch(globalAlertFail(onFailMsg ? onFailMsg : "Could fetch data"));
        if (onFailFn) {
          onFailFn(err);
          setAxiosGetIsLoading(false);
          return;
        }
        setAxiosGetIsLoading(false);
      });
  }, []);

  //Post Request
  const axiosPostRequest = useCallback(
    async ({ name, path, payload, onSuccessFn, onSuccessMsg, onFailFn, onFailMsg, headers, formData, type }) => {
      setAxiosPostIsLoading(true);
      console.log("<--------");
      console.log(
        `%cPOST |>| ${name} || path --> ${getValueFromMappedObject(domainTypeMap, type)}/${path}`,
        "background: #08a6081e;"
      );
      console.log(`%cPOST |>| ${name} || payload --> `, "background: #08a6081e;", payload);
      // console.log("STOP POST");
      // return;
      axios({
        url: `${getValueFromMappedObject(domainTypeMap, type)}/${path}`,
        method: "post",
        headers: {
          ...headers,
        },
        data: formData ? mapNestedObjectFormData(payload) : payload,
      })
        .then((response) => {
          const responseData = response.data;

          console.log(`%cPOST |<| ${name} || Response Data --> `, "background: #08a6083b;", responseData);
          console.log("-------->");
          if (onSuccessFn) {
            onSuccessFn(responseData);
          }

          if (onSuccessMsg) {
            globalAlertSuccess(onSuccessMsg);
          }
          setAxiosPostIsLoading(false);
        })
        .catch((err) => {
          console.log(`%cPOST |<| ${name} || Error --> `, "background: #ff00001a;", err);
          console.log("-------->");
          setAxiosError(err.message);
          dispatch(globalAlertFail(onFailMsg ? onFailMsg : "Could submit data"));
          if (onFailFn) {
            onFailFn(err);
            setAxiosPostIsLoading(false);
            return;
          }
          setAxiosPostIsLoading(false);
        });
    },
    []
  );

  return {
    axiosPostIsLoading,
    axiosGetIsLoading,
    axiosError,
    axiosGetRequest,
    // axiosGetBlob,
    axiosPostRequest,
  };
};
