import { Box } from "@mui/material";
import React from "react";

const RealDealLogoSvg = ({ height = "auto", width = "90px", white }) => {
  return (
    <Box
      role="button"
      onClick={() => {
        window.open("https://www.scotsmanguide.com/", "_blank");
      }}
      component={"svg"}
      sx={{
        cursor: "pointer",
        ".cls-1": {
          fill: "#fff",
        },
        ".cls-2": { fill: "#41c4d9" },
      }}
      xmlns="http://www.w3.org/2000/svg"
      // viewBox="0 0 668.46 92.75"
      viewBox="0 0 597 144"
      width={width}
      height={height}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          fill={white ? "#ffffff" : "#231F20"}
          d="M124.08,100.66V27.21h41.25v13.85h-24.89v15.18h17.13v12.54h-17.13v17.47h24.89v14.4H124.08z M99,100.66V71.51H81.63v29.15H65.27V27.21h16.36v30.13H99V27.21h16.36v73.44H99z M26.05,100.66V27.54H6.69V10.4h58.54v17.14H46.01v73.11H26.05z"
        />
        <path
          fill="#D83830"
          d="M234.37,100.82v-5.65c-2.42-2.15-3.76-4.97-3.76-9.14V71.39c0-6.18-3.9-12.9-11.96-14.79c8.06-2.15,11.56-7.66,11.56-14.92v-9.67c0-12.9-8.74-21.24-23.25-21.24H174.7v89.51h19.22V63.72h10.35c5.51,0,7.93,4.03,7.93,8.74v14.65c0,8.33,2.95,13.71,10.75,13.71H234.37z M211.66,42.49c0,5.38-2.69,7.93-7.66,7.93h-10.08V25.83h10.89c4.97,0,6.85,2.82,6.85,7.25V42.49z M280.64,100.28V86.64h-24.89V68.41h17.14V56.62h-17.14V40.68h24.89v-13.1h-40.5v72.69H280.64zM333.88,100.28l-16.92-72.69H299.5l-17.03,72.69h14.63l3.06-14.41h15.06l3.06,14.41H333.88z M313.14,74.3h-10.7l5.56-26.52L313.14,74.3z M377.51,100.28V86.85h-23.9V27.59h-15.61v72.69H377.51z"
        />
        <path
          fill={white ? "#ffffff" : "#231F20"}
          d="M445.58,74.48V37.65c0-14.92-9.95-26.88-28.49-26.88h-29.84v89.51h29.84C435.64,100.28,445.58,89.53,445.58,74.48 M426.36,73c0,7.12-3.36,11.29-10.75,11.29h-9.14V26.76h9.14c7.39,0,10.75,4.17,10.75,11.29V73z M494.14,100.28V86.64h-24.89V68.41h17.14V56.62h-17.14V40.68h24.89v-13.1h-40.5v72.69H494.14zM547.37,100.28l-16.92-72.69h-17.47l-17.02,72.69h14.63l3.06-14.41h15.06l3.06,14.41H547.37z M526.63,74.3h-10.7l5.57-26.52L526.63,74.3z M591,100.28V86.85h-23.9V27.59h-15.61v72.69H591z"
        />
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M128.35,131.33h-4.08v-17.34h7.62c2.24,0,3.46,0.31,4.5,1.17c1.12,0.91,1.82,2.47,1.82,4.03 c0,1.25-0.47,2.5-1.3,3.38c-0.42,0.44-0.81,0.7-1.69,1.12l3.2,7.64h-4.5l-2.65-6.86h-2.91V131.33z M128.35,121.5h2.5 c1.35,0,1.66-0.05,2.16-0.36c0.6-0.36,1.01-1.14,1.01-1.9c0-0.68-0.31-1.33-0.81-1.72c-0.52-0.39-0.83-0.44-2.47-0.44h-2.39V121.5z " />
        <path d="M151.67,117.29v3.56h6.5v3.25h-6.5v3.8h8.27v3.43h-12.4v-17.34h12.4v3.3H151.67z" />
        <path d="M183.16,131.33h-4.37l-1.07-3.56h-5.59l-1.09,3.56h-3.56l5.54-17.34h4.71L183.16,131.33z M176.79,124.57 l-1.85-6.08l-1.85,6.08H176.79z" />
        <path d="M202.35,127.9v3.43h-11v-17.34h4.11v13.91H202.35z" />
        <path d="M227.7,117.29v3.56h6.5v3.25h-6.5v3.8h8.27v3.43h-12.4v-17.34h12.4v3.3H227.7z" />
        <path d="M253.59,118.96c-0.62-1.56-1.38-2.05-3.02-2.05c-1.51,0-2.37,0.57-2.37,1.53c0,1.01,0.62,1.46,2.5,1.82 c2.6,0.55,3.67,0.88,4.55,1.48c1.46,0.99,2.24,2.44,2.24,4.11c0,1.82-0.99,3.61-2.55,4.63c-1.12,0.73-2.76,1.12-4.58,1.12 c-2.03,0-3.67-0.49-4.97-1.48c-1.09-0.86-1.59-1.64-2.08-3.33l3.74-0.86c0.49,1.66,1.59,2.42,3.51,2.42c1.72,0,2.78-0.7,2.78-1.82 c0-1.04-0.7-1.51-3.02-1.98c-1.92-0.39-3.04-0.75-4-1.35c-1.35-0.81-2.11-2.24-2.11-3.95c0-1.61,0.6-2.96,1.77-4 s2.6-1.51,4.55-1.51c1.87,0,3.38,0.44,4.55,1.33c0.91,0.68,1.38,1.35,1.92,2.76L253.59,118.96z" />
        <path d="M277.59,117.42h-4.29v13.91h-4.11v-13.91h-4.32v-3.43h12.71V117.42z" />
        <path d="M298.47,131.33h-4.37l-1.07-3.56h-5.59l-1.09,3.56h-3.56l5.54-17.34h4.71L298.47,131.33z M292.1,124.57 l-1.85-6.08l-1.85,6.08H292.1z" />
        <path d="M316.43,117.42h-4.29v13.91h-4.11v-13.91h-4.32v-3.43h12.71V117.42z" />
        <path d="M328.65,117.29v3.56h6.5v3.25h-6.5v3.8h8.27v3.43h-12.4v-17.34h12.4v3.3H328.65z" />
        <path d="M371.81,131.33h-3.48l-6.79-11.73c0.03,0.29,0.03,0.52,0.03,0.6c0.03,0.39,0.05,0.65,0.05,0.75v10.37h-3.15 v-17.34h4.37l5.9,10.22c-0.05-0.55-0.08-0.81-0.08-1.14v-9.07h3.15V131.33z" />
        <path d="M385.75,117.29v3.56h6.5v3.25h-6.5v3.8h8.27v3.43h-12.4v-17.34h12.4v3.3H385.75z" />
        <path d="M419.63,131.33h-3.98l-2.86-11.47c-0.1,0.44-0.13,0.57-0.23,0.99l-2.5,10.48h-3.98l-4.45-17.34h4.13 l2.39,10.58c0,0.05,0.05,0.26,0.16,0.57c0,0.05,0.08,0.29,0.13,0.52l2.83-11.67h3.74l2.91,11.67c0.08-0.26,0.1-0.44,0.13-0.52 c0.05-0.18,0.08-0.36,0.13-0.57l2.47-10.58H424L419.63,131.33z" />
        <path d="M440.74,118.96c-0.62-1.56-1.38-2.05-3.02-2.05c-1.51,0-2.37,0.57-2.37,1.53c0,1.01,0.62,1.46,2.5,1.82 c2.6,0.55,3.67,0.88,4.55,1.48c1.46,0.99,2.24,2.44,2.24,4.11c0,1.82-0.99,3.61-2.55,4.63c-1.12,0.73-2.76,1.12-4.58,1.12 c-2.03,0-3.67-0.49-4.97-1.48c-1.09-0.86-1.59-1.64-2.08-3.33l3.74-0.86c0.49,1.66,1.59,2.42,3.51,2.42c1.72,0,2.78-0.7,2.78-1.82 c0-1.04-0.7-1.51-3.02-1.98c-1.92-0.39-3.04-0.75-4-1.35c-1.35-0.81-2.11-2.24-2.11-3.95c0-1.61,0.6-2.96,1.77-4 s2.6-1.51,4.55-1.51c1.87,0,3.38,0.44,4.55,1.33c0.91,0.68,1.38,1.35,1.92,2.76L440.74,118.96z" />
      </g>
    </Box>
  );
};

export default RealDealLogoSvg;
