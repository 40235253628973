import { Box } from "@mui/material";
import React from "react";

const ScotsmanLogoSvg = ({ height = "auto", width = "90px" }) => {
  return (
    <Box
      role="button"
      onClick={() => {
        window.open("https://www.scotsmanguide.com/", "_blank");
      }}
      component={"svg"}
      sx={{
        cursor: "pointer",
        ".cls-1": {
          fill: "#fff",
        },
        ".cls-2": { fill: "#41c4d9" },
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 668.46 92.75"
      width={width}
      height={height}
    >
      <path
        class="cls-1"
        d="M579.32,21.52a6.25,6.25,0,0,1-12.45,0c0-3.12,2.79-5.64,6.22-5.64S579.32,18.4,579.32,21.52Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M219.7,30.43c-13.12,0-20.06,8.08-20.06,23.38,0,15.14,7.06,23.48,19.87,23.48,13.31,0,20.06-8,20.06-23.9C239.57,38.58,232.51,30.43,219.7,30.43Zm-.09,37.83c-3.23,0-6.94-1.63-6.94-14.36s3.71-14.45,6.94-14.45,6.93,1.63,6.93,14.36S222.84,68.26,219.61,68.26Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M262.3,67.49c-5.06,0-5.21-1.28-5.21-9V40.91h9V31.46h-9V19.57l-12.25.88V59.06c0,9.38,0,18.23,14.19,18.23l8.44-.86.19-1.11v-8.2l-2.44.21Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M356.7,30.43c-6.72,0-10,3.2-11.75,6-1.3-2.8-4-6-11.11-6-5.51,0-8.46,2.11-10.39,4.42V31.46H311.76v44.8h12.45V50.38c0-5.53.48-10.5,6.27-10.5A3.81,3.81,0,0,1,334,41.49c.78,1.19.8,1.68.8,7.76v27h12.45V50.2c0-9.14,3.29-10.32,6.17-10.32,4.05,0,4.43,2.47,4.43,9V76.26h12.45v-28c0-4.85-.17-9.83-2.22-12.76C365,30.91,359.09,30.43,356.7,30.43Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M442,30.43c-5.5,0-8.5,2.19-10.4,4.43v-3.4H419.94v44.8h12.45V51.32c0-5.53.5-11.44,6.35-11.44,4,0,4.35,2,4.35,8.34v28h12.45V47.71c0-4.54-.13-8.53-1.78-11.4C451.56,32.46,447.5,30.43,442,30.43Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M548.18,53c0,8.94-.46,14.79-6.56,14.79-3.79,0-4.15-1.45-4.15-8.26V31.46H525V59.75c0,7,0,17.54,13.24,17.54,5.7,0,8.7-2.11,10.69-4.45v3.42h11.67V31.46H548.18Z"
        transform="translate(-0.1)"
      />
      <rect class="cls-1" x="566.83" y="31.46" width="12.45" height="44.8" />
      <path
        class="cls-1"
        d="M611.66,34c-1.82-1.68-4.61-3.29-9.54-3.29-10.82,0-17.55,9-17.55,23.39,0,5.39,1.23,23,17.17,23,5.65,0,8.67-2.06,10.58-4.22v3.44H624V16.49H611.66Zm-1.79,8.58c1.79,2.43,1.79,4.92,1.79,7.57v7.31c0,4.4-.69,6.24-1.8,7.76A6.57,6.57,0,0,1,604.52,68c-4.79,0-6.93-4.54-6.93-14.71,0-9.39,2.18-13.77,6.84-13.77A6.76,6.76,0,0,1,609.87,42.55Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M494.81,54.41h11.35c-.15,5.76-1.49,12.65-9.64,12.65-7.9,0-11.74-6.78-11.74-20.73,0-18.5,6.59-20.64,11.55-20.64,6.68,0,8.34,4.49,9.93,10.84v0l12.13-3c-1.89-8.52-6.18-18.14-22.17-18.14-22.05,0-25.35,19.88-25.35,31.73,0,14.55,6.36,30.1,24.19,30.1,8.1,0,12.28-3.22,14.62-5.94l1,5.34h8.12V44.44h-24Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M185.79,59l0,.54c-.46,4.28-1.24,8.11-5.79,8.11-6.31,0-7-7.36-7-12.9,0-10.91,1.9-15,7-15,3.74,0,4.88,2.23,5.63,7.09l11.68-3c-1.19-5.21-4.71-13.44-17.41-13.44S160,38.79,160,54c0,15,6.82,23.31,19.2,23.31,14.77,0,17.47-9.75,18.38-15.25Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M137.61,40.13c-8.18-2.87-11.91-4.37-11.91-8.42,0-.25.08-6,7.41-6,5.86,0,8.2,3.07,10.08,8l.43,1.15,11.5-3.62-.38-1.11c-1.36-4-5-14.69-21.05-14.69-14.44,0-21,9-21,17.37a16,16,0,0,0,6.22,12.6c3.7,2.86,6.91,3.94,14,6.33,5.72,1.88,10.09,3.68,10.09,8.26,0,1.64-.66,7-9.05,7-7.5,0-10.08-4.51-11.16-7.62l-12,2.94c1.67,5.21,6.37,14.91,22.89,14.91,15.7,0,22.85-9.63,22.85-18.57C156.44,46.48,145.11,42.66,137.61,40.13Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M656.93,60.38l-.23.91c-.94,3.67-2.19,7-7.08,7-6.42,0-7.6-5.57-7.72-11.87h26.66l0-1.22c-.09-5.25-.2-10.69-3.61-16.7-2.22-3.89-7.47-8-15.49-8-9.79,0-20.25,6.32-20.25,24.07,0,14.28,7.53,22.79,20.15,22.79,9.85,0,16.15-4.77,18.74-14.19Zm-7.79-21.1a6.37,6.37,0,0,1,6.05,4.32,13.82,13.82,0,0,1,.75,4.46h-13.7C643,42.31,645.28,39.28,649.14,39.28Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M291.72,48.8c-6.8-2-8.58-2.6-8.58-5.48,0-1.86,1.26-4,4.82-4,4.19,0,5.74,2.79,6.71,5.53l.17.46L305,42.65l-.13-.43c-2.38-7.83-8-11.79-16.65-11.79-11.09,0-17.08,7.35-17.08,14.27,0,9.47,9,12.08,15.59,14,4.72,1.31,7.66,2.36,7.66,5.37,0,.73-.29,4.39-6.07,4.39s-7.25-3.38-7.82-6.32l-.23-1.2-5.37.93-5.54,1.36.2.92c.7,3.25,2.85,13.16,18.37,13.16,12.86,0,18.72-7.57,18.72-14.62C306.68,53.11,297.32,50.42,291.72,48.8Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-1"
        d="M414.52,74.78c-.74-2.79-.74-8-.74-11.51V48.91c0-4.82,0-10.27-3.34-13.54-2.12-2.24-6.32-4.94-14-4.94-4.92,0-16.51,1.24-19.06,12.54L389,46l.22-1.06c.6-2.79,1.81-5.64,6.38-5.64,5.25,0,5.69,3.91,5.69,7.91v.5a51.61,51.61,0,0,0-15.13,1.8,14.6,14.6,0,0,0-10.71,14.38c0,8.16,5.73,13.42,14.58,13.42,6.59,0,10.16-2.89,12.38-5.65.17,1.83.46,4.76.46,4.76l1.19-.14h10.8Zm-13.19-18.3c0,8.22-4.15,12-8,12-3.47,0-5-1.59-5-5.17s1.31-7.82,11.45-7.82l1.56,0Z"
        transform="translate(-0.1)"
      />
      <path
        class="cls-2"
        d="M40.07,33.19c-19.36,6.18-32.31,19.56-31,34.16.93,10.29,8.85,18.86,20.5,24C21.3,86,15.79,78.43,15,69.55,13.43,52.79,25.76,37.34,47.55,33.19,30,39,21.46,52.07,22.74,66c1,11.28,10.48,20.43,23.82,24.89A56.84,56.84,0,0,0,63.8,92.67l.44-.15C45.76,92.16,30.5,81.13,29.12,66.3s7.61-29.46,25.7-33c-14.58,5-19.27,16.35-19.27,27.55,0,16.3,15.83,24.53,28,24.53,23.61,0,31.32-23.26,31.15-38.56C94.46,26.54,78.49,0,47.44,0,25,0,.1,17.72.1,46.89A47.46,47.46,0,0,0,1.83,60.61C6.3,47.43,21,36.73,40.07,33.19Z"
        transform="translate(-0.1)"
      />
    </Box>
  );
};

export default ScotsmanLogoSvg;
