import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { isBoolean, isNil, isString } from "lodash";

const Flex = ({
  children,
  sx,
  center,
  end,
  column,
  wrap = false,
  ac,
  jc,
  fw,
  g,
  ai,
  className,
  grow,
  jb, // New prop for justifyContent: 'space-between'
}) => {
  // Create a dynamic style object
  const styles = useMemo(() => {
    const dynamicStyles = {
      flexDirection: column ? "column" : "row",
      justifyContent: jb
        ? "space-between"
        : center || isBoolean(jc)
        ? "center"
        : isString(jc)
        ? jc
        : end
        ? "flex-end"
        : "flex-start",
      alignItems: center || ac ? "center" : isString(ai) ? ai : "stretch",
      ...(!isNil(g) ? { gap: isString(g) ? g : `${g}px` } : {}),
      ...(!isNil(grow) ? { grow: grow } : {}),
    };

    return {
      display: "flex",
      flexWrap: wrap ? "wrap" : "nowrap",
      width: fw ? "100%" : "auto",
      height: "auto",
      boxSizing: "border-box",
      ...dynamicStyles,
      ...sx,
    };
  }, [end, column, ac, jc, g, wrap, center, sx, ai, grow, jb, fw]);

  return (
    <Box className={className} sx={styles}>
      {children}
    </Box>
  );
};

Flex.propTypes = {
  sx: PropTypes.object, // Changed to object to properly accept styles
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
  end: PropTypes.bool,
  column: PropTypes.bool,
  jc: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  wrap: PropTypes.bool,
  g: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  grow: PropTypes.number,
  className: PropTypes.string,
  ai: PropTypes.string,
  jb: PropTypes.bool, // New prop type for jb
};
export default Flex;
