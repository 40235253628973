import React, { useState } from "react";

// import "./BorrowerCreditAuthWorkspace.css";
import { EmailOutlined, MailOutline, PhoneOutlined, StayCurrentPortraitOutlined } from "@mui/icons-material";
import { Box, Card, Checkbox, Divider, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { Field, useForm } from "react-final-form";
import FlatironLogoSvg from "../../components/FlatironLogoSvg";
import Flex from "../../components/Flex";
import ScotsmanLogoSvg from "../../components/ScotsmanLogoSvg";
import Form from "../../components/form/Form/Form";
import { SelectFieldComponent } from "../../components/form/components/inputs/finalFormComponents/SelectFieldComponent";
import { TextFieldComponent } from "../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import MuiGrid from "../../components/shared/MuiGrid";
import GridItem from "../../components/shared/MuiGrid/GridItem";
import SuccessContent from "../../components/shared/SuccessContent";
import { parsePhoneNumber } from "../../helpers/parse/parsePhoneNumber";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../../helpers/validators/inputValidators";
import { useFormsHook } from "../../hooks/useFormsHook";

const maxWidth = 720;

const ContactFormCardScreen = ({ config }) => {
  const { logo, headerBorderBottomColor, headerBackgroundColor, flatironLogoWhite, pathname } = config;
  return (
    <>
      {/* <BackgroundShape /> */}
      <PageWrapper>
        <Card sx={{ maxWidth: `${maxWidth}px`, margin: "14px auto", width: "100%", borderRadius: "16px" }}>
          <CardInnerWrapper>
            <CardHeader
              logo={logo}
              headerBorderBottomColor={headerBorderBottomColor}
              headerBackgroundColor={headerBackgroundColor}
              flatironLogoWhite={flatironLogoWhite}
            />
            <ContactForm pathname={pathname} />
          </CardInnerWrapper>
        </Card>
      </PageWrapper>
    </>
  );
};

/**
 * Sections
 */

const CardHeader = ({ logo, flatironLogoWhite, headerBackgroundColor, headerBorderBottomColor }) => {
  return (
    <Flex column sx={{ background: headerBackgroundColor, borderBottom: `4px solid ${headerBorderBottomColor}` }}>
      <ContentContainer>
        <Flex wrap center g={8}>
          <FlatironLogoSvg white={flatironLogoWhite} height={32} width={"auto"} />
          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderColor: flatironLogoWhite ? "#ffffff5e" : "#0000005e", margin: "4px 8px" }}
          />
          {logo}
        </Flex>
        <Flex
          column
          center
          g={8}
          sx={{ color: flatironLogoWhite ? "#ffffff" : "#222222", textAlign: "center", marginTop: 2.4 }}
        >
          <Typography
            sx={{
              fontSize: "28px",

              fontWeight: 800,
            }}
            variant="h1"
          >
            Contact Flatiron Realty Capital
          </Typography>
          <Typography variant="subtitle" sx={{ fontSize: "17px" }}>
            Your partner in private lending.
          </Typography>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

const ContactForm = ({ pathname }) => {
  const { isLoading, submitContactForm } = useFormsHook();
  const [success, setSuccess] = useState(null);

  const onFormSubmit = async (formState) => {
    const fullName = `${formState?.firstName} ${formState?.lastName}`;
    let combinedPayload = { ...formState };
    combinedPayload["fullName"] = fullName;

    await submitContactForm({
      payload: combinedPayload,
      pathname: pathname,
      onSuccessFn: () => setSuccess(true),
    });
  };

  if (success) {
    return (
      <ContentContainer>
        <Flex column center sx={{ textAlign: "center" }}>
          <SuccessContent />
          <Typography variant="h2" sx={{ fontSize: "22px" }}>
            Thank You for Contacting Us!
          </Typography>
          <Typography variant="subtitle1">We appreciate you reaching our team get back to you shortly.</Typography>
        </Flex>
      </ContentContainer>
    );
  }
  return (
    <ContentContainer>
      <Form
        isLoading={isLoading}
        // formSpy
        onClick={onFormSubmit}
        initialValues={{ textOptIn: false }}
        //
      >
        <FormGroup noMargin fieldLabel="Contact Information">
          <MuiGrid spacing={1.5}>
            <GridItem size={6}>
              <Field
                validate={VALIDATOR_REQUIRE}
                name={"firstName"}
                label="First Name"
                component={TextFieldComponent}
              />
            </GridItem>
            <GridItem size={6}>
              <Field validate={VALIDATOR_REQUIRE} name={"lastName"} label="Last Name" component={TextFieldComponent} />
            </GridItem>

            <GridItem size={12}>
              <Field
                validate={VALIDATOR_EMAIL}
                name={"emailAddress"}
                label={`Email Address`}
                icon={<EmailOutlined />}
                component={TextFieldComponent}
              />
            </GridItem>
            <GridItem size={12}>
              <Field
                validate={VALIDATOR_REQUIRE}
                name={"phone"}
                icon={<PhoneOutlined />}
                parse={parsePhoneNumber}
                label={`Phone Number`}
                component={TextFieldComponent}
              />
            </GridItem>
            <GridItem size={12}>
              <Field
                validate={VALIDATOR_REQUIRE}
                noMargin
                label="Profession"
                name={"profession"}
                items={[
                  "Direct Borrower",
                  "Investor",
                  "Third Party Originator",
                  "Loan Officer",
                  "Realtor",
                  "Real Estate Attorney",
                  "Other Real Estate Professional",
                ]}
                component={SelectFieldComponent}
              />
            </GridItem>
            <GridItem size={12}>
              <TextOptinCheckbox
                icon={<StayCurrentPortraitOutlined />}
                label={"Yes, I would like to opt into text messages"}
                name={"textOptIn"}
              />
            </GridItem>
            <GridItem size={12}>
              <TextOptinCheckbox
                icon={<MailOutline />}
                // label={"Add me to your mailing list"}
                label={"Stay up to date and join our mailing list"}
                name={"emailOptIn"}
              />
            </GridItem>
          </MuiGrid>
        </FormGroup>
      </Form>
    </ContentContainer>
  );
};

const TextOptinCheckbox = ({ name, label, icon }) => {
  // const { values } = useFormState();
  const { change } = useForm();

  const onChange = (v) => {
    change(name, v?.target?.checked);
  };
  // return (
  //   <Flex gap={4} ac>
  //     {icon}
  //     <Checkbox onChange={onChange} />
  //     <FormLabel>{label}</FormLabel>
  //   </Flex>
  // );
  return <FormControlLabel control={<Checkbox onChange={onChange} />} label={label} />;
};

/**
 * STATELESS UI COMPONENTS
 */

const CardInnerWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        //
        // maxWidth: "720px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        // gap: "32px",
      }}
    >
      {children}
    </Box>
  );
};

const ContentContainer = ({ children }) => {
  return (
    <Flex
      column
      fw
      sx={{
        //
        maxWidth: `calc(${maxWidth}px - 72px)`,
        margin: "auto",

        gap: "32px",
        pb: 5,
        pt: 5,
        pl: 2,
        pr: 2,
      }}
    >
      {children}
    </Flex>
  );
};

const PageWrapper = ({ children }) => {
  return (
    <>
      <Box
        component={"svg"}
        sx={{ position: "fixed", left: 0 }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 435.51 526.53"
      >
        <rect width="435.51" height="526.53" fill="#edf2f5" />

        <path
          d="M0,150 C80,100 160,200 240,150 C320,100 400,200 480,150 L480,526.53 L0,526.53 Z"
          fill="#c3dafe"
          opacity="0.6"
        />
        {/* 
        <path
          d="M0,300 C100,350 200,250 300,300 C400,350 500,250 600,300 L600,526.53 L0,526.53 Z"
          fill="#a3bffa"
          opacity="0.6"
        /> */}
        {/* 
        <path
          d="M0,450 C120,400 240,500 360,450 C480,400 600,500 720,450 L720,526.53 L0,526.53 Z"
          fill="#90cdf4"
          opacity="0.4"
        /> */}
      </Box>
      <Box sx={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", overflowY: "auto", padding: 1 }}>
        {children}
      </Box>
    </>
  );
};

export default ContactFormCardScreen;
