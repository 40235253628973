import React from "react";

import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { setGlobalData } from "../redux/actions/globalDataActions";
import AuctionComScreen from "./screens/AuctionComScreen";
import BorrowerContactUsScreen from "./screens/BorrowerContactUsScreen";
import BorrowerCreditAuthScreen from "./screens/BorrowerCreditAuthScreen";
import BorrowerLoanApplicationScreen from "./screens/BorrowerLoanApplicationScreen";
import FlatironFormsSplashPage from "./screens/FlatironFormsSplashPage";
import CalendarInviteScreen from "./screens/CalendarInviteScreen/CalendarInviteScreen";
import ScottsmanGuideFromScreen from "./screens/ScottsmanGuideFromScreen";
import ContactFormCardScreen from "./screens/ContactFormCardScreen";
import RealDealLogoSvg from "../components/RealDealLogoSvg";
import ScotsmanLogoSvg from "../components/ScotsmanLogoSvg";

const UnauthenticatedView = (props) => {
  const globalData = useSelector((state) => state.globalData);
  const isMobile = globalData.isMobile;
  const dispatch = useDispatch();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 900) {
        const updatedData = {
          ...globalData,
          isOpen: false,
          isMobile: true,
        };
        dispatch(setGlobalData(updatedData));
      } else {
        const updatedData = {
          ...globalData,
          isOpen: true,
          isMobile: false,
        };
        dispatch(setGlobalData(updatedData));
      }
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    console.log("window.scrollY", window.scrollY);
    if (isMobile && window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  return (
    <Box
      id="borrower"
      sx={{
        // overflowY: "auto"
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      component={"main"}
    >
      <Routes>
        <Route path="/*" element={<FlatironFormsSplashPage />} />
        <Route path="/loan-application" element={<BorrowerLoanApplicationScreen />} />
        <Route path="/contact-auction-borrower" element={<BorrowerContactUsScreen />} />
        <Route path="/credit-authorization" element={<BorrowerCreditAuthScreen />} />
        <Route path="/auction-preapproval" element={<AuctionComScreen />} />
        <Route path="/auction-preapproval-contact-me" element={<AuctionComScreen />} />
        <Route path="/meeting/*" element={<CalendarInviteScreen />} />
        <Route
          path="/scotsman-guide/*"
          element={
            <ContactFormCardScreen
              config={{
                logo: <ScotsmanLogoSvg height={20} width={"auto"} />,
                headerBorderBottomColor: "#00d3e6",
                headerBackgroundColor: "#00334f",
                flatironLogoWhite: true,
                pathname: "scotsman-guide",
                // headerBackgroundColor: "#ffffff5e"
              }}
            />
          }
        />
        {/* <Route path="/scotsman-guide/*" element={<ScottsmanGuideFromScreen />} /> */}
        <Route
          path="/the-real-deal/*"
          element={
            <ContactFormCardScreen
              config={{
                logo: <RealDealLogoSvg height={26} width={"auto"} white />,
                headerBorderBottomColor: "#d93831",
                pathname: "the-real-deal",
                headerBackgroundColor: "#231F20",
                flatironLogoWhite: true,
              }}
            />
          }
        />

        <Route path="*" element={<Navigate to={`/`} replace />} />
      </Routes>
      {/* <BackgroundShape /> */}
    </Box>
  );
};

export default UnauthenticatedView;
